<template>
  <div class="w-full about">
    <h1 class="mb-4 text-2xl">Admin</h1>
    <button
      class="px-4 py-2 mr-2 text-lg text-gray-100 bg-green-500 rounded hover:opacity-75"
      @click="logout"
    >
      Logout
    </button>
  </div>
</template>

<script>
import { ref, onBeforeMount } from "vue";
import { logout, getAllOfPageType } from "../firebase.js";

export default {
  setup() {
    const allPortfolios = ref({});
    onBeforeMount(() => {
      getAllOfPageType("portfolio", "list").then(
        (data) => (allPortfolios.value = data)
      );
    });
    return {
      allPortfolios,
      getAllOfPageType,
      logout,
    };
  },
};
</script>